import '../../styles/pages/fall-winter-2023/farm-to-pantry.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
// import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2023-stories/more-stories"

import socialBanner from '../../images/fall-winter-2023/social-banners/farm-to-pantry.jpg';

import Hero from '../../images/fall-winter-2023/farm-to-pantry/hero.jpg';
import peaches from '../../images/fall-winter-2023/farm-to-pantry/peaches.jpg';
import avocados from '../../images/fall-winter-2023/farm-to-pantry/avocados.jpg';
import oranges from '../../images/fall-winter-2023/farm-to-pantry/oranges.jpg';
import AmyBulone from '../../images/fall-winter-2023/farm-to-pantry/amy-bulone.jpg';
import compost from '../../images/fall-winter-2023/farm-to-pantry/compost.jpg';
import pantryPeaches from '../../images/fall-winter-2023/farm-to-pantry/pantry-peaches.jpg';
import pantryOranges from '../../images/fall-winter-2023/farm-to-pantry/pantry-oranges.jpg';
import gardenHerbs from '../../images/fall-winter-2023/farm-to-pantry/garden-herbs.jpg';
import gardenCarrots from '../../images/fall-winter-2023/farm-to-pantry/garden-carrots.jpg';
import ctaBanner from '../../images/fall-winter-2023/farm-to-pantry/cta-banner.jpg';



export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'From Farm to Food Pantry';
    var pageDesc = 'The Fullerton Arboretum and CSUF Campus Garden keep the ASI Food Pantry stocked with fresh fruits and vegetables.';
    var slug = 'farm-to-pantry';

    var heroStyles = {
      backgroundImage: `url(${Hero})`,
    }

    return (
      <Layout headerStyle="black" locationInfo={this.props.location} issue="fall-winter-2023">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2023 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <p className="sub-heading animated fadeInDown delay-1s">{pageDesc}</p>
          </div>
        </div>
        <article className='wrap' id="copy-wrap">

          <div className='wrap small'>

            <p><span className='intro-text'>Jada Fonseca holds a pair of pruners in one hand</span> and gently grabs a ripe desert gold peach hanging from a branch with the other. She handpicks the fruit at the Fullerton Arboretum, filling a container with six ready-to-eat peaches. </p>

            <p>Fonseca, a Cal State Fullerton senior, volunteers to gather the day’s harvest of peaches, avocados and Valencia oranges, which will help feed fellow students who have been impacted by historically high costs of food and other living expenses.</p>

            <figure className='fruit-gallery align-wide'>
              <div style={{ backgroundImage: `url(${peaches})` }} className='peaches' />
              <div style={{ backgroundImage: `url(${avocados})` }} className='avocados' />
              <div style={{ backgroundImage: `url(${oranges})` }} className='oranges' />
            </figure>

            <p>Through a partnership with Associated Students Inc. Food Pantry (The Pantry), students experiencing food insecurity can reap the benefits of surplus harvests from the arboretum and CSUF Campus Garden. </p>

            <figure className='align-right align-outside large' id="amy-bulone">
              <img src={AmyBulone} alt="Amy Bulone cuts fruit from a tree" />
              <figcaption>Amy Bulone, Fullerton Arboretum horticulturist</figcaption>
            </figure>
            
            <p>“The fruit trees we care for can make a difference to students,” says arboretum horticulturist Amy Bulone. “Public garden horticulture is all about connecting people to plants, and there is no easier way to do that than by putting food on the table.”</p>

            <p>Fonseca knows firsthand the benefits of having a campus food pantry with fresh-picked produce. In the past, she turned to The Pantry to help her with weekly groceries and learned about other resources that help students, such as food assistance programs and local food banks.</p>

            <p>“I work a minimum wage job. I have to pay rent, bills and school expenses,” says Fonseca. “I don’t always have a lot of extra money for food. Thankfully, The Pantry has helped provide me with the food I needed.”</p>

            <p>Since opening in August 2021, 3,333 students have accessed The Pantry. </p>

            <p>“As we work to become a well-known service among all students at CSUF, we anticipate serving more students every year,” shares Keya Allen, ASI associate executive director.</p>

            <p><a href="https://asi.fullerton.edu/services/#FoodPantry" target='_blank' rel='noreferrer'>The Pantry</a> has received more than 2,800 pounds of fresh fruit from the arboretum since the partnership started in early 2022.</p>
            
            <p>The 26-acre <a href="https://fullertonarboretum.org/" target='_blank' rel='noreferrer'>arboretum</a> grows a bounty of other fresh fruit, including apples, pears, plums, nectarines, lemons and limes. Crops vary each season to donate to The Pantry, with the arboretum’s team harvesting pears and apples this fall, followed by persimmons and pomegranates. </p>

            <p>The arboretum’s crops also include tomatoes, peppers, carrots, zucchini, herbs and, later in the fall season, leafy greens such as chard and lettuce.</p>

            <p>In return for the fresh fruit, pantry produce past its shelf-life is taken to the arboretum to recycle into compost.</p>

            <p>“Old produce from The Pantry results in compost that goes back underneath the fruit trees to nourish new fruit — coming full circle,” Bulone says.</p>

          </div>
          <figure id="compost">
            <img src={compost} alt="Amy Bulone operates a tractor to move compost" />
            <figcaption>Amy Bulone</figcaption>
          </figure>
          <div className='wrap small'>

            <h2>Creating a Healthy Food Pantry</h2>
            <p>A 2018 study of students’ basic needs by the California State University Office of the Chancellor found that nearly 42% of students across CSU campuses experienced food insecurity. </p>

            <p>During the pandemic, a campus survey conducted by public health faculty members Tabashir Nobari and Maria Koleilat revealed that 19% of the students surveyed faced chronic food insecurity.</p>

            <p>“It’s hard for students to make ends meet with high rent, educational expenses and transportation,” relays Chris Mikaelian, Class of 2023 international business graduate who served as 2022-23 ASI vice president. </p>

            <p>“Basic human needs should be the last thing any student should worry about when obtaining an education. The Pantry is an essential service that helps students maintain their well-being and achieve student success.”</p>

            <figure className='align-wide'>
              <div className='side-by-side-gallery'>
                <img src={pantryPeaches} alt="" />
                <img src={pantryOranges} alt="" />
              </div>
              <figcaption className='center' style={{ border: 'none'}}>
              The Pantry, located in Titan Student Union, is stocked with fresh fruits for CSUF students.
              </figcaption>
            </figure>

            <p>To address students’ basic needs at Cal State Fullerton, ASI started a monthly mobile food pantry in the fall of 2018. </p>

            <p>Three years later, a permanent 700-square-foot pantry opened in Titan Student Union. Students can shop for free produce and essential food items — eggs, milk, bread, snacks, ready-to-eat frozen foods and soups — in a confidential environment.</p>

            <p>“Early on, it was clear that ASI needed to increase food resources and operating hours for CSUF students and improve accessibility to tackle food insecurity on campus,” says Dave Edwards, ASI executive director. “We want students’ basic needs met with fewer hurdles.” </p>

            <p>The Pantry is moving to a more spacious and prominent location in the Titan Student Union in October. The new space is double the size of the current location and can serve an increased number of students weekly.</p>

            <p>This increased need among students is due to the rising cost of living and the challenging responsibilities that college students face, Allen notes.</p>

            <h2>Promoting Sustainable Systems</h2>
            <p>In addition to the arboretum partnership, the <a href="https://sustainability.fullerton.edu/campusgarden/" target='_blank' rel="noreferrer">CSUF Campus Garden</a> and The Pantry began working together earlier this year to address student food insecurity and hunger issues. The garden opened last spring between the Humanities-Social Sciences Building and Gordon Hall. </p>

            <p>Student organizations, campus departments, centers and classes oversee the garden’s nine beds, which sprouted bountiful harvests of vegetables and herbs in spring. The garden has donated carrots, radishes, kale, sugar snap peas, and herbs, including cilantro, parsley and sorrel. </p>

            <figure className='side-by-side-gallery align-wide' >
              <div style={{ backgroundImage: `url(${gardenCarrots}` }} />
              <div style={{ backgroundImage: `url(${gardenHerbs}` }} />
            </figure>

            <p>“Each group is responsible for maintaining its bed for a semester or academic year, and many have chosen to provision their produce to The Pantry,” says <a href="http://anthro.fullerton.edu/People/JohnBock.aspx" target='_blank' rel="noreferrer ">John Bock</a>, professor of anthropology and director of the Center for Sustainability, which provides training and support in growing, maintaining and harvesting.</p>

            <p>The center’s future endeavors include expanding the garden with additional beds, offering plots to individuals and starting a garden club.</p>

            <p>“The garden is an opportunity for the campus community to experience all the benefits of gardening and to recognize the importance of a resilient and sustainable local food system to address food justice and food and nutritional insecurity,” Bock adds.</p>

            <p>“Through our collaborative efforts with the arboretum and garden, The Pantry is addressing food insecurity among CSUF students by distributing food and produce at no cost that is nutritious and easily accessible,” says Allen. <span className='end-mark' /></p>

            <p><small>Photos by Matt Gush</small></p>

          </div>
        </article>
        <div className='cta-banner' style={{ backgroundImage: `url(${ctaBanner})` }}>
          <div className='cta-text'>
            <p>
              <span className='cta-title'>Support the ASI Food Pantry</span><br />
              <a href="https://give.fullerton.edu/foodpantry" target='_blank' rel='noreferrer'>give.fullerton.edu/foodpantry</a>
            </p> 
          </div>
        </div>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 